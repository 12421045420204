<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <Powered />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Powered from '../../components/Powered.vue'
import { db } from "../../firebase";
import { doc, getDoc, Timestamp, updateDoc } from "firebase/firestore"
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Powered,
  },
  data() {
    return {
      clientTag: {
          client: '',
          tag: ''
        }
    }
  },

  computed: {
    ...mapState(['tag'])
      
  },

  methods: {
    ...mapActions(['setClientTag']),

    async goToUrl(){
        try {
          this.clientTag.client = "A105"
          this.clientTag.tag = this.$route.params.tag
          await this.setClientTag(this.clientTag)
          const url = JSON.parse(localStorage.getItem('urlkin'))
          //console.log(url)
          //console.log(this.tag)

          const visit = Object.assign({}, this.tag)
          visit.interaction = 'SCAN'
          visit.created = Timestamp.now()
          //console.log(visit)

          if(visit.tagId && visit.clientId && visit.enabled === true){
            this.updateVisits(visit.tagId)
            //window.location.replace(url)
          }
          else{
            //console.log("No existe tag y/o cliente...")
            //window.location.replace("https://anukin.com")
          }
        } catch (error) {
          console.log(error)
        }
     
      },

      async updateVisits(tag) {
        try {
          const docRef = doc(db, `custom/A105/interactions/${tag}`)
          const docSnap = await getDoc(docRef)
          const interactions = docSnap.data().interactions + 1

          const ref = doc(db, `custom/A105/interactions`, `${tag}`)
          await updateDoc(docRef, {
            interactions: interactions
          })
          
        } catch (error) {
          console.log(error)
        }
        
      }


  },

  mounted() {
      this.goToUrl()
  },

}
</script>

